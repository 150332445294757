import React from "react";
import Modal from "react-bootstrap/Modal";
const CusModal = ({ show, onHide, children ,className }) => {
  return (
    <div>
      <Modal show={show} onHide={onHide} backdrop="static" 
      centered
      className={className}>
        {children}
      </Modal>
    </div>
  );
};

export default CusModal;
