import "./style.scss";
import React, { useState, useEffect } from "react";
import { Routes } from "./components/routes/Routes";
import { Toaster } from "react-hot-toast";
import TrackShipmentModal from "../src/components/modal/TrackShipmentModal";

function App() {
  const [showTrackModal, setShowTrackModal] = useState(false);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.ctrlKey && event.key === "k") {
        event.preventDefault();
        setShowTrackModal(true);
      }
    };

    const handleStorageChange = (event) => {
      if (event.key === "logout") {
        sessionStorage.clear();
        localStorage.clear();
        window.location.href = process.env.REACT_APP_KEYCLOACK_POST_LOGOUT;
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  return (
    <>
      <Toaster position="top-right" reverseOrder={false} />
      <Routes />
      <TrackShipmentModal show={showTrackModal} onHide={setShowTrackModal} />
    </>
  );
}

export default App;
