import React, { useState, useEffect, useRef } from "react";
import CusModal from "./CusModal";
import NavigateIcon from "../../assets/img/NavigateIcon.svg";
import { Modal } from "react-bootstrap";

let timeoutId = null;

const TrackShipmentModal = ({ show, onHide }) => {
  const [trackNavigate, setTrackNavigate] = useState("");
  const inputRef = useRef(null); // Ref for input field

  const handleTrackInputChange = (e) => {
    const input = e.target.value;
    // Limit input to 18 characters
    if (input.length <= 18) {
      setTrackNavigate(input);
    }
    // Automatically navigate after 450ms if the input has 15 or more characters
    if (input.length >= 15) {
      if (timeoutId) clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        navigateToPage(input.slice(0, 15)); // Navigate with the first 15 characters
      }, 450);
    }
  };

  // Navigation function
  const navigateToPage = (trackingId) => {
    const trackingIdToNavigate = trackingId.slice(0, 15); // Always use the first 15 characters
    if (trackingIdToNavigate.length === 15) {
      const token = sessionStorage.getItem("token");
      sessionStorage.setItem("token", token);
      const url = `/consignments/orders/${trackingIdToNavigate}`;
      window.open(url, "_blank");
      setTrackNavigate("");
    }
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.ctrlKey && event.key === "k") {
        event.preventDefault();
        onHide(true); 
        setTimeout(() => {
          inputRef.current?.focus();
        }, 100);
      }
    };

    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [onHide]);

  return (
    <CusModal show={show} onHide={() => onHide(false)} className="addnewtracking">
      <Modal.Header closeButton>
        <h4 className="fontSize16 blackText fontWeight500">Track Shipment</h4>
      </Modal.Header>
      <div className="modelScrollTrack">
        <Modal.Body>
          <div className="modalBody">
            <div className="displayFlex alignItemCenter position-relative">
              <input
                type="text"
                ref={inputRef}
                value={trackNavigate}
                className="mainInput withIcon"
                style={trackNavigate.length > 0 ? { border: "2px solid #ec171c" } : {}}
                placeholder="Track Shipment"
                onChange={handleTrackInputChange}
              />
              <button
                className="inputIconButton"
                title="View"
                disabled={trackNavigate.length < 15}
                onClick={() => navigateToPage(trackNavigate.slice(0, 15))}
              >
                <span>
                  <img src={NavigateIcon} className="turnBlack" alt="View" />
                </span>
              </button>
            </div>
          </div>
        </Modal.Body>
      </div>
    </CusModal>
  );
};

export default TrackShipmentModal;
